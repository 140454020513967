<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <UserNav activePage="adminnotifications" activeTab="2"></UserNav>
                <!--begin::Content-->
                <div class="flex-row-fluid ml-lg-8">
                    <!--begin::Content-->
                    <div class="flex-row-fluid ">
                        <div class="row">
                            <div class="col-lg-12">
                                <!--begin::Card-->
                                <div class="card card-custom gutter-b example example-compact">
                                    <div class="card-header">
                                        <h3 class="card-title">Send Notifications</h3>
                                    </div>
                                    <form class="form" novalidate="novalidate" id="kt_save_changes_form">
                                        <div class="card-body">
                                            <div class="mb-15">
                                                <div class="form-group">
                                                    <label>Title: <span class="text-danger">*</span></label>
                                                    <input type="text" name="title" ref="title" class="form-control"
                                                        placeholder="Enter the title" v-model="form.title" />
                                                    <span class="form-text text-dark">Please enter the title</span>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleTextarea">Text <span
                                                            class="text-danger">*</span></label>
                                                    <textarea class="form-control" name="body" ref="body" id="body" rows="3"
                                                        :maxlength="max" v-model="form.body"></textarea>
                                                    <span class="form-text text-dark"
                                                        v-text="(max - form.body.length)"></span>
                                                </div>
                                                <div class="form-group">
                                                    <label>Image:</label>
                                                    <input type="text" name="image" ref="image" class="form-control"
                                                        placeholder="Enter the image url" v-model="form.image" />
                                                    <span class="form-text text-dark">Please enter the image url.
                                                        (optional)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <button ref="kt_save_changes" class="btn btn-primary mr-2">Send</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card card-custom card-stretch gutter-b">
                                    <!--begin::Header-->
                                    <div class="card-header border-0">
                                        <h3 class="card-title font-weight-bolder text-dark fs-15">Previous Notifications
                                        </h3>
                                    </div>
                                    <!--end::Header-->
                                    <!--begin::Body-->
                                    <div class="card-body pt-2">
                                        <b-table id="coaches-table" :items="previousNotifications" :fields="fields"
                                            :per-page="perPage" :current-page="currentPage" show-empty
                                            class="table table-borderless table-vertical-center">
                                            <template #cell(image)="data" class="pl-0 py-4">
                                                <div class="symbol symbol-50 symbol-light mr-1"
                                                    v-if="data.item.image != ''">
                                                    <span class="symbol-label">
                                                        <img :src="imageSource(data.item.image)"
                                                            class="h-100 w-100 obcover br-42 align-self-center" alt="" />
                                                    </span>
                                                </div>
                                            </template>
                                            <template #cell(title)="data" class="pl-0 py-4">
                                                {{ data.item.title }}
                                            </template>
                                            <template #cell(body)="data" class="pl-0 py-4">
                                                {{ data.item.body }}
                                            </template>
                                            <template #cell(timestamp)="data" class="pl-0 py-4">
                                                {{ shortFormatDate(data.item.timestamp) }}
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import formateDateMixin from "@/mixins/formatDate";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
    name: 'notifications',
    mixins: [defaultProfileImageMixin, formateDateMixin],
    components: {
        UserNav
    },
    data() {
        return {
            max: 1000,
            perPage: 10,
            currentPage: 1,
            fields: [
                { key: "image", label: "" },
                { key: "title", label: "Title" },
                { key: "body", label: "Text" },
                { key: "timestamp", label: "Date" },
            ],
            form: {
                title: "",
                body: "",
                image: ""
            }
        }
    },
    mounted() {
        this.$store.dispatch("getPreviousNotifications")

        const changes_form = KTUtil.getById("kt_save_changes_form");

        this.fv = formValidation(changes_form, {
            fields: {
                title: {
                    validators: {
                        notEmpty: {
                            message: "The title is required",
                        },
                    },
                },
                body: {
                    validators: {
                        notEmpty: {
                            message: "The text is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        let self = this;
        this.fv.on("core.form.valid", () => {
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("sendGeneralNotification", {
                title: this.form.title,
                body: this.form.body,
                image: this.form.image
            })
                .then(
                    function (value) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        self.form.title = ""
                        self.form.body = ""
                        self.form.image = ""
                        Swal.fire({
                            title: "",
                            text: "The message will be sent out in a few minutes.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        })
                    }
                )
        });

        this.fv.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    computed: {
        ...mapGetters(["previousNotifications"])
    }
}
</script>
